<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Retailer</label>
      <div v-if="form.id && form.id.length > 0" class="col-3 mt-2">
        {{form.merchantType}}
      </div>
      <div v-else class="col-2">
        <select v-model="form.merchantType" class="form-select">
          <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Order Id</label>
      <div class="col-3">
            <input type="text" v-model="form.orderId" class="form-control"/>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Date</label>
      <div class="col-3">
            <datepicker v-model="form.date" typeable class="form-control" style="width: 280px"/>
      </div>
    </div>
    <div v-for="(item, index) in form.usageList" :key="index">
      <div class="mb-3 row">
        <label class="col-1 col-form-label">Gift Card</label>
        <div v-if="form.id && form.id.length > 0" class="col-3 mt-2">
            {{form.usageList[0].card}}
        </div>
        <div v-else class="col-3">
            <Autocomplete input-class="form-control" :items="giftCardList" :parentRef="item" @autocompleteSelected="selectedItem" :init-value="item.card"></Autocomplete>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-1 col-form-label">Amount</label>
        <div class="col-2">
              <input type="text" v-model="item.amount" class="form-control" style="display:inline;"/>
              <span class="btn btn-link" v-if = "index === 0 && form.id.length === 0" v-on:click="form.usageList.push({card:'', amount:''})">Add More</span> 
              <span class="btn btn-link" v-else-if = "index === form.usageList.length-1 && form.id.length === 0" v-on:click="form.usageList.pop()">Remove</span> 
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-1 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "GiftCardEdit",
  data: function () {
    return {
      form: {
        id: this.$route.query.id || "",
        merchantType: "Best Buy",
        date: new Date(),
        usageList: [{card: "", amount: ""}]
      },
      breadcrumbs: [{ name: "Gift Card", link: "/giftcard" }, { name: "Transaction", link: "/giftcard/transaction" }, { name: (this.$route.query.id ? "Update" : "New") + " Transaction" }],
      alertContent: "",
      alertClass: "alert-danger",
      retailerList: ["Best Buy", "Amazon", "Walmart", "Costco", "Dell", "Microsoft", "Google", "Sony", "Target", "Gamestop", "HP", "Sams Club", "HSN", "Sephora", "eBay", "Staples"],
      giftCardList: []
    };
  },
  mounted() {
      if (this.form.id) {
         this.getObject();
      }
      this.getGiftCardList();
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      this.submitted = true;
      axios.post("/giftcard/transaction", this.form)
      .then(response => {
        this.submitted = false;
        this.alertContent = response.data;
        this.alertClass = "alert-success";
      })
      .catch(error => {
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
        this.submitted = false;
      });
    },
    getObject() {
      axios
      .get("/giftcard/transaction/" + this.form.id)
      .then((response) => {
        this.form = response.data;
        this.form.usageList = [{
          card: response.data.giftcard,
          amount: response.data.amount
        }];
        this.form.date = new Date(this.form.date);
        this.alertContent = "Gift Card transaction(s) have been saved successfully!";
        this.alertClass = "alert-success";
      })
      .catch((error) => {
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      });
    },
    getGiftCardList() {
      axios
      .get("/giftcard?status=New,Unknown,Failed,Canceled&type=" + this.form.merchantType)
      .then((response) => {
        this.giftCardList = response.data.map( item => item.number );
      })
      .catch((error) => {
        this.alertContent = error.response ? error.response.data : error;
        this.alertClass = "alert-danger";
      });
    },
    selectedItem(item, parentRef) {
      parentRef.card = item;
    }
  }
};
</script>